.iq-wrapper .well {
    clear: both;
}
.iq-wrapper .nav-pills > li > a {
    font-family: "PT Serif";
    font-size: 18px;
}
@media (max-width: 768px) {
    .iq-wrapper .nav-pills > li > a {
        font-size: 14px;
    }
}

.iq-wrapper .nav-pills > li.active > a {
    background-color: inherit;
    color: rgb(44,49,48);
}

.nav.nav-pills-xs > li > a {
    padding: 10px 10px;
}