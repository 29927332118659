.btn {
    border-radius: 5px !important;
    min-width: 100px;
}
.btn-green {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#15b253+0,129a48+100 */
    background: #15b253; /* Old browsers */
    background: -moz-linear-gradient(top,  #15b253 0%, #129a48 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #15b253 0%,#129a48 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #15b253 0%,#129a48 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#15b253', endColorstr='#129a48',GradientType=0 ); /* IE6-9 */
}

.btn.iq-button-grey {
    background-color: rgb(238,231,222);
    border: none;
    color: rgb(44,49,48);
    display: block;
    height: 50pt;
    margin-bottom: 10px;
    text-align: left;
}
.btn.iq-button-grey a {
    color: rgb(44,49,48);
    text-decoration: none;
}


.iq-wrapper-result .btn.iq-button-grey {
    margin: 20px 0;
}