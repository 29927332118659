.quiz-box__heading {
    position: relative;
    text-align: left;
    font-size: 27px;
    padding: 30px 0 30px;
    text-transform: uppercase;
    color: #000;
    font-family: "Liberation Sans", sans-serif;
    font-weight: 700;
}
.quiz-box__heading:after {
    display: block;
    content: '';
    width: 62px;
    height: 3px;
    background: black;
    position: absolute;
    bottom: 0;
    left: 0;
}


@media (min-width: 700px) {
    .quiz-box__heading {
        text-align: center;
        font-size: 39px;
        padding: 80px 0 30px;
    }
    .quiz-box__heading:after {
        left: 0;
        right: 0;
        margin: auto;
    }
}