.personality-result-top {
    position: relative;
    margin-bottom: 100px;
}

.personality-lifetime {
    background-color: #dae1e7;
    height: 140px;
    padding: 25px;
}

.personality-result-top > .personality-image {
    border-radius: 50%;
    width: auto;
    height: 150px;

    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50px;
}

.personality-character h1 {
    font-family: 'Crimson Text', serif;
    font-size: 60px;
    font-weight: bold;
}

.personality-text {
    margin: 0 50px;
    padding: 20px 0 10px;
    text-align: left;
}
.personality-character-intro {
    /*background-color: #ccc;*/
    /*font-style: italic;*/
}

.h2{
    font-size: 34px !important;
}

@media (max-width: 768px) {
    .personality-result-top h1 {
        font-size: 24px;
    }

    .personality-character h1 {
        font-size: 30px;
        margin: 5px;
    }

    .personality-result-top > .personality-image {
        height: 90px;
        top: 100px;
    }

    .personality-text {
        padding: 0 10px;
    }
    .job .personality-text, .job .job-text {
        margin: 0 10px;
    }
}