.category {
    width: 100%;
}

.category__items {
    display: flex;
    flex-wrap: wrap;
}

@media (min-width: 700px) {
    .category {
        padding: 15px 80px;
    }
}
