@font-face {
    font-family: 'Tungsten';
    src: url('/fonts/Tungsten-Medium.eot');
    src: url('/fonts/Tungsten-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Tungsten-Medium.woff') format('woff'),
    url('/fonts/Tungsten-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Vitesse';
    src: url('/fonts/Vitesse-Black.eot');
    src: url('/fonts/Vitesse-Black.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Vitesse-Black.woff') format('woff'),
    url('/fonts/Vitesse-Black.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Book';
    src: url('/fonts/Tungsten-Book.eot');
    src: url('/fonts/Tungsten-Book.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Tungsten-Book.woff') format('woff'),
    url('/fonts/Tungsten-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Vitesse Medium';
    src: url('/fonts/Vitesse-Medium.eot');
    src: url('/fonts/Vitesse-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Vitesse-Medium.woff') format('woff'),
    url('/fonts/Vitesse-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Light';
    src: url('/fonts/Tungsten-Light.eot');
    src: url('/fonts/Tungsten-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Tungsten-Light.woff') format('woff'),
    url('/fonts/Tungsten-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Vitesse Bold';
    src: url('/fonts/Vitesse-Bold.eot');
    src: url('/fonts/Vitesse-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Vitesse-Bold.woff') format('woff'),
    url('/fonts/Vitesse-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Vitesse Book';
    src: url('/fonts/Vitesse-Book.eot');
    src: url('/fonts/Vitesse-Book.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Vitesse-Book.woff') format('woff'),
    url('/fonts/Vitesse-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



.btn-personality-red {
    color: #ffffff;
    background-color: #FF0004;
}

.btn-personality-red:hover,
.btn-personality-red:active,
.btn-personality-red:focus {
    color: #ffffff;
    background-color: #FF1418;
}

.btn-personality-yellow {
    color: #1F1F1D;
    background-color: #FFD600;
}

.btn-personality-yellow:hover,
.btn-personality-yellow:active,
.btn-personality-yellow:focus {
    color: #1F1F1D;
    background-color: #FFDE33;
}

.job .btn-personality-yellow,
.job .btn-personality-yellow:hover,
.job .btn-personality-yellow:active,
.job .btn-personality-yellow:focus {
    color: #1F1F1D;
    background-color: #ffff00;
}


.btn-personality-blue {
    color: #ffffff;
    background-color: #24569F;
}

.btn-personality-blue:hover,
.btn-personality-blue:active,
.btn-personality-blue:focus {
    color: #ffffff;
    background-color: #3368B5;
}

body {
    font-family: 'Crimson Text', serif;
}
body .job {
    font-family: 'Tungsten', sans-serif;
}

.font-sc {
    font-family: 'Playfair Display SC', serif;
}
.container.quiz {
    padding: 0;
}

h2,
.h2 {
    font-size: 34px !important;
}

@media (max-width: 568px) {
    .container, .widget {
        padding: 0 !important;
        overflow-x: hidden;
        width:100%;
    }
    .footer-bg .footer{
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 960px;
    }
    .footer-bg .footer{
        padding-left: 15px;
        margin-left: 15px;
    }
}

.btn-facebook, .quiz-restart button {
    font-size: 24px;
    margin: 25px 0;
    padding: 10px 30px;
}

.btn-facebook:hover {
    background-color: #8b9dc3 !important;
    color: #fff !important;
}


.btn.disabled, .disabled.btn-facebook, .disabled.story-button, .btn[disabled], [disabled].btn-facebook, [disabled].story-button, fieldset[disabled] .btn, fieldset[disabled] .btn-facebook, fieldset[disabled] .story-button {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-primary, .story-button {
    color: #2c2720;
    background-color: #FFD700;
    border-color: #f2cc00;
}

.btn-primary.disabled, .disabled.story-button, .btn-primary.disabled:hover, .disabled.story-button:hover, .btn-primary.disabled:focus, .disabled.story-button:focus, .btn-primary.disabled:active, .disabled.story-button:active, .btn-primary.disabled.active, .disabled.active.story-button, .btn-primary[disabled], [disabled].story-button, .btn-primary[disabled]:hover, [disabled].story-button:hover, .btn-primary[disabled]:focus, [disabled].story-button:focus, .btn-primary[disabled]:active, [disabled].story-button:active, .btn-primary[disabled].active, [disabled].active.story-button, fieldset[disabled] .btn-primary, fieldset[disabled] .story-button, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .story-button:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .story-button:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .story-button:active, fieldset[disabled] .btn-primary.active, fieldset[disabled] .active.story-button {
    background-color: #FFD700;
    border-color: #f2cc00;
}

.center {
    margin: 0 auto;
    display: block;
}

.btn-green {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#15b253+0,129a48+100 */
    background: #15b253; /* Old browsers */
    background: -moz-linear-gradient(top,  #15b253 0%, #129a48 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #15b253 0%,#129a48 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #15b253 0%,#129a48 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#15b253', endColorstr='#129a48',GradientType=0 ); /* IE6-9 */
    border-color: #15b253;
}

.font-bold {
    font-weight: bold;
}

#logo {
    width:200px;
    margin-left: -16px;
    margin-top: 15px;
}
.footer-bg a:visited{
    color: #999999 !important;
}

.footer-bg a:hover{
    color: #fff !important;
}