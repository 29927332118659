.iq-timed-text-choice-ticker {
    min-height: 100px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    text-align: center;
}

.iq-timed-text-choice-ticker > span {
    font-size: 40px;
}

.iq-timed-text-choice-ticker.iq-xs {
    min-height: 50px;
    padding: 10px;
}
.iq-timed-text-choice-ticker.iq-xs > span {
    font-size: 20px;
}

.iq-timed-text-choice-item {
    cursor: pointer;

    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
         -o-user-select: none;
            user-select: none;
}
