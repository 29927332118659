.category__item {
    display: flex;
    width: 100%;
    max-width: 400px;
    min-height: 88px;
    padding: 15px 0;
    box-sizing: content-box;
}

.category__item:hover .category__item__icon {
    transform: scale(1.07);
}

.category__item__icon {
    position: absolute;
    background-color: #FFD745;
    border-radius: 3px;
    height: 88px;
    width: 88px;
    transition: 0.3s ease;
}

.category__item__text {
    padding: 0 10px 0 108px;
    display: flex;
    flex-direction: column;
}

.category__item__title {
    margin-bottom: 5px;
    font-size: 20px;
    color: #000;
    font-family: "Liberation Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    word-break: break-all;
}

.category__item__description {
    font-size: 15px;
    font-family: "Liberation Sans", sans-serif;
}

@media (min-width: 700px) {
    .category__items__row:nth-last-of-type(1) {
        border-bottom: 0;
    }

    .category__item {
        margin-right: 20px;
        padding: 25px 0;
    }

    .category__item__text {
        padding: 5px 20px 5px 108px;
    }

    .category__item__title {
        margin-bottom: 15px;
    }
}