.result-shop {
    font-family: "Tungsten";
    font-size: 20px;
    margin: 20px 0;
}
.result-shop .btn {
    font-size: 36px;
    margin-top: 20px;
    padding: 10px 25%;
}

@media (min-width: 768px) {
    .vertical-center {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 1048px) {
    .result-shop div {
        margin-bottom: 10px;
    }
}
