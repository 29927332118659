.quiz-box {
    position: relative;
    width: 100%;
    background: url("../../image/nordic/background.svg") 100% no-repeat;
    background-size: cover;
}
.quiz-box__body {
    padding: 0 20px;
}

.quiz-box__text-box {
    max-width: 615px;
    padding: 30px 0 0;
    font-size: 17px;
    line-height: 29px;
}
.quiz-box__text-box a {
    color: #E4B300;
    cursor: pointer;
    text-decoration: underline;
}
.quiz-box__text-box--large {
    max-width: 780px;
}
.quiz-box__text-box--italic {
    font-style: italic;
}
.quiz-box__button-wrap {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: baseline;
}
.quiz-box__footer {
    display: block;
    margin-top: 35px;
    width: 100%;
}

@media (min-width: 700px) {
    .quiz-box__text-box {
        padding: 30px 80px 0;
        font-size: 18px;
        line-height: 30px;
    }
    .quiz-box__text-box--center {
        margin: auto;
        text-align: center;
        padding: 30px 0 0;
    }
    .quiz-box__button-wrap {
        padding: 0 80px;
    }
    .quiz-box__button-wrap--center {
        justify-content: center;
    }
}