.iq-memory-sequence {
    margin-bottom: 10px;
}

.iq-memory-sequence > .iq-memory-sequence-area {
    margin-bottom: 20px;
}

.iq-memory-sequence-button {
    min-height: 100px;
    margin-bottom: 20px;
    font-size: 40px;
}

.iq-memory-sequence-display {
    min-height: 100px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    text-align: center;
}

.iq-memory-sequence-display > span {
    font-size: 40px;
    text-transform: uppercase;
}

.iq-memory-sequence-input {
    font-size: 30px;
    line-height: 30px;
    min-height: 70px;
    letter-spacing: 8px;
    text-transform: uppercase;
    text-align: center;
}

