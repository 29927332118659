h2.personality-question {
    font-family: 'Crimson Text', serif;
}
.personality-question-answers {
    list-style: none;
    padding-left: 0;
}

.personality-question-answers > .personality-question-answer {
    font-family: 'BenchNine';
    min-height:       80px;
    line-height:  80px;
    font-size:    24px;
    padding-left: 20px;
    border-left:  10px solid transparent;

    cursor: pointer;

    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
         -o-user-select: none;
            user-select: none;
}

.personality-question-answers > .personality-question-answer:nth-of-type(odd) {
    background-color: #f9f9f9;
}
.job .personality-question-answers > .personality-question-answer:nth-of-type(odd) {
    border-bottom: 1px solid black;
}

.personality-question-answers > .personality-question-answer:hover {
    border-left: 10px solid #FED51B;
    background-color: #FDF3C8;
}

.personality-question-answers > .personality-question-answer.active {
    border-left: 10px solid #929292;
}

@media (max-width: 768px) {
    h2.personality-question {
        font-size: 24px;
    }
    .personality-question-answers > .personality-question-answer {
        font-size:   18px;
        min-height:  40px;
        line-height: 40px;
    }
}