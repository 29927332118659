.personality-statistics-personalities {
    min-height: 500px;
    background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),url('/image/personality/parchment.jpg');
    background-size: 100%;
    padding: 15px;
}
.job .personality-statistics-personalities {
    background: white;
}

.personality-statistics-personalities > .row {
    margin-bottom: 40px;
    text-align: center;
}

.personality-statistics-personalities-header {
    font-family: "BenchNine";
    font-size: 48px;
    color: #ffffff;
    padding: 20px 0;
    text-transform: uppercase;
    font-weight: bold;
}
.job .personality-statistics-personalities-header {
    color: black;
    font-family: Tungsten, sans-serif;
    font-size: 36px;
    text-transform: none;
}

.personality-statistics-personalities-personality {
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
}
.job .personality-statistics-personalities-personality {
    color: black;
}

.personality-statistics-personalities-personality > img.image {
    border-radius: 50%;
    box-shadow: 6px 8px 15px rgba(136, 136, 136, 0.49);
    cursor: pointer;
    height: 200px;
    margin-bottom: 5px;
    width: auto;
}
.job .personality-statistics-personalities-personality > img.image {
    border: none;
    box-shadow: none;
    border-radius: 0;
}

.personality-statistics-personalities-personality > p.name {
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
}
.job .personality-statistics-personalities-personality > p.name {
    font-family: "Vitesse Medium";
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    min-height: 50px;
    text-transform: uppercase;
}

.personality-statistics-personalities-personality-text > .personality-text {
    background-color: white;
    color: black;
    font-weight: normal;
    margin: 0 85px 20px 85px;
    opacity: 0.75;
    padding: 40px 96px;
    text-align: left;
}
.personality-statistics-personalities-personality-text > .personality-text > span {
    display: inline;
}
.job .personality-statistics-personalities-personality-text > .personality-text {
    font-family: "Vitesse Medium";
}

.personality-statistics-personalities-personality > a.read-more {
    font-family: "BenchNine";
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
}
.job .personality-statistics-personalities-personality > a.read-more {
    color: black;
    font-family: Tungsten, sans-serif;
}
.personality-statistics-personalities-personality > a.active {
    color: black;
}
.job .personality-statistics-personalities-personality > a.active {
    color: grey;
}

.personality-statistics-personalities-personality > hr {
    width: 45%;
    margin-top: 0px;
    margin-bottom: 5px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}

.personality-statistics-personalities-personality > p.percent {
    font-size: 51px;
    font-weight: bolder;
    color: #FFD600;
}
.job .personality-statistics-personalities-personality > p.percent {
    color: #4996c7;
    font-family: Vitesse, serif;
    cursor: pointer;
}

.job .personality-profile-teaser {
    display: none;
}

.personality-profile-teaser {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url('/image/personality/profile_teaser.jpg');
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    min-height: 645px;
}
.personality-profile-teaser > .profile-header {
    font-family: "BenchNine";
    color: white;
    margin-left: 55%;
    padding-top: 10%;
    font-size: 3em;
}
.personality-profile-teaser > .profile-name {
    color: white;
    margin-left: 55%;
    font-size: 1.5em;
}
.personality-profile-teaser > .profile-text {
    color: white;
    margin-top: 8em;
    padding: 0 200px;
    text-align: center;
    vertical-align: bottom;
}

.btn-personality-yellow {
    margin-bottom: 20px;
}

.job .known_for {
    display: none;
}

@media (max-width: 1024px) {
    .personality-profile-teaser > .profile-text {
        padding: 0 20px;
    }
    .personality-profile-teaser {
        min-height: 500px
    }
}
@media (max-width: 768px) {
    .personality-statistics-personalities-header {
        font-size: 36px;
    }

    .personality-statistics-personalities-personality > p.percent {
        font-size: 36px;
    }

    .personality-statistics-personalities-personality > p.name {
        height: 50px;
        overflow: hidden;
    }
    .job .personality-statistics-personalities-personality > p.name {
        font-size: 14px;
    }

    .personality-statistics-personalities-personality > img.image {
        height: 90px;
    }

    .personality-statistics-personalities-personality-text > p.personality-text {
        margin: 0 20px;
    }

    .personality-statistics-personalities-personality-text > .personality-text {
        margin: 0 10px 0 10px;
        opacity: 0.75;
        padding: 40px 10px;
        text-align: left;
    }

    .personality-profile-teaser {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/image/personality/profile_teaser_cut.jpg');
        background-origin: content-box;
        background-repeat: no-repeat;
        background-size: 100%;

    }

    .personality-profile-teaser > .profile-header,
    .personality-profile-teaser > .profile-name {
        margin-left: 0;
        text-align: center;
    }

    .personality-profile-teaser > .profile-text {
        margin-top: 2em;
    }
}

}
@media (max-width: 468px) {
    .personality-statistics-personalities-header {
        font-size: 24px;
    }
    .job .personality-statistics-personalities-personality {
        padding: 0 2px;
    }
    .job .personality-statistics-personalities-personality p {
        padding: 0 !important;
    }

    .job .personality-statistics-personalities-personality > p.percent {
        font-size: 24px;
    }
    .job .personality-statistics-personalities-personality > p.name {
        font-size: 11px;
        padding: 0;
    }
}