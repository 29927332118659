.iq-result-total-header h2 {
    font-family: "Roboto Condensed";
    font-weight: 600;
    font-size: 36px;
    text-transform: uppercase;
}

.iq-result-total-header h1 {
    font-family: "Roboto Condensed";
    font-weight: 600;
    font-size: 88px;
}

