.personality-intro h2 {
    font-family: "BenchNine";
    font-size: 48px;
    text-align: center;
    margin-bottom: 40px;
    clear:both;
}
.job .personality-intro h2 {
    color: #4996c7;
    font-family: "Tungsten", sanf-serif;
    font-size: 60px;
    text-transform: uppercase;
}
.job .personality-intro p {
    font-size: 32px;
}