.category__items__row {
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    width: 100%;
}

@media (min-width: 700px) {
    .category__items__row {
        flex-direction: row;
        border-bottom: 1px solid #E8E8E8;
    }
}