.quiz-box_top_image {
    padding-top: 2em;
    padding-bottom: 2em;
    max-width: 38em;
}

.quiz-box_top_paragraph {
    margin-bottom: 2em;
}

@media (max-width: 700px) {
    .quiz-box_top_image {
        max-width: 90%;
    }

    .quiz-box_top_container {
        text-align: left;
        padding-left: 0px;
    }

    .quiz-box_top_paragraph {
        margin-bottom: 0em;
    }
}

