.paywall-magazines {
    margin: 10px 0;
    text-align: center;
}
.paywall-magazines > img {
    height: 280px;
}

.paywall > .paywall-header {
    background-color: #DEDEDE;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}

.paywall > .paywall-form {
    background-color: #EFEFEF;
    padding: 40px;
}

.paywall-permission-text {
    font-size: 13px;
    text-align: left;
}
.paywall-permission-text a {
    cursor: pointer;
}

.btn-facebook {
    color: #ffffff;
    background-color: #24569F;
}

.btn-facebook:hover,
.btn-facebook:active,
.btn-facebook:focus {
    color: #ffffff;
    background-color: #3368B5;
}

.btn.btn-block.btn-green {
    background-color: green;
    color: white;
}

.paywall-permission-text a {
    color: #3368B5;
}

