.personality-statistics-participation {
    background-color: #1C1C1C;
    padding: 15px;
    padding-bottom: 30px;
    color: #ffffff;
}

.personality-statistics-participation > .row {
    margin-bottom: 30px;
}
.personality-statistics-participation > .row:last-child {
    margin-bottom: 0px;
}

.personality-statistics-participation > .row > :not(:last-child) {
    border-right: 1px solid #363636;
}

.personality-statistics-participation-header {
    font-family: "BenchNine";
    text-align: center;
    font-size: 31px;
    color: #ffffff;
    text-transform: uppercase;
}

.job .personality-statistics-participation-header {
    font-family: Tungsten, sans-serif;
}

.personality-statistics-participation-counter {
    text-align: center;
    font-size: 90px;
    color: #ffffff;
}

.personality-statistics-participation-description {
    text-align: center;
    font-size: 20px;
    color: #C7AF6F;
}

.personality-statistics-participation-bottom {
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}

@media (max-width: 568px) {
    .personality-statistics-participation-counter {
        font-size: 45px;
    }
    .personality-statistics-participation-description {
        font-size: 12px;
    }
}