.personality-type {
    background-color: #eeeeee;
    padding: 10px;
    margin-bottom: 15px;
    margin-top: 15px
}
.job .personality-type,
.job .personality-type h1 {
    font-family: Vitesse;
}

.personality-type h1 {
    font-family: 'BenchNine';
}

.personality-type p {
    padding: 0 50px;
}

.trait {
    font-family: 'BenchNine', sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
}
.job .trait {
    font-size: 15px;
}
.trait i {
    color: #C0BDAE;
    margin-right: 10px;
}
.trait-percentage {
    font-family: 'Crimson Text', serif;
    font-size: 48px;
    font-weight: bold;
}
.trait-percentage.majority {
    color: #807350;
}
.job .trait-percentage.majority {
    color: #00729e;
}

.trait .tooltip-inner {
    font-family: 'Crimson Text', serif;
}

@media (max-width: 768px) {
    .trait {
        font-size: 16px;
    }
    .trait-percentage {
        font-size: 24px;
    }
    .personality-result-radial canvas {
        width: 100px;
    }
    .personality-type p {
        padding: 0 10px;
    }
    .job .personality-type h1 {
        font-size: 30px;
    }
    .scale-left .tooltip.left {
        width: 200px;
        margin-left: 200px;
    }
}