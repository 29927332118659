.nordic-result-total-header h2 {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 600;
    font-size: 36px;
    text-transform: uppercase;
}

.nordic-result-total-header h1 {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 600;
    font-size: 88px;
}

