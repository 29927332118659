.btn-facebook {
    font-family: 'BenchNine';
    font-size: 24px;
    margin: 25px 0;
    padding: 10px 30px;
}
.job .btn-facebook {
    margin: 0;
}
.btn-facebook i {
    font-size: 1.7em;
    margin-right: 10px;
}
.btn-facebook:hover,
.btn-facebook:active,
.btn-facebook:focus {
    color: #ffffff;
    background-color: #3368B5;
}

.result-facebook-share {
    background-color: black;
    padding: 10px 0;
}

.result-facebook-share h1 {
    color: white;
    font-family: 'BenchNine';
    font-size: 24px;
    text-transform: uppercase;
}

@media (max-width: 400px) {
    .btn-facebook {
        font-size: 18px;
    }
}