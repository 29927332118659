.iq-result-section {
    height: 350px;
}

.iq-result-section h4 {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 18px;
}

.iq-result-section-plot img {
    /*background-image: url(/image/iq/bar-plot.png);*/
    /*background-repeat: no-repeat;*/

    height: 160px;
    line-height: 160px;
}

.iq-result-section-plot-inner {
    background-color: rgb(52,112,133);
    width: 50px;
    height: 50px;
    margin-left: 100px;
}
