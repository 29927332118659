.quiz-box__hero__icon {
    background-color: #FFD745;
    border-radius: 3px;
    height: 65px;
    width: 65px;
    flex-shrink: 0;
}

.quiz-box__hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    min-height: 113px;
    border-bottom: 1px solid #E8E8E8;
    margin: 0 -20px;
}
.quiz-box__hero__heading {
    font-size: 27px;
    text-transform: uppercase;
    color: #000;
    font-family: "Liberation Sans", sans-serif;
    font-weight: 700;
}
.quiz-box__hero__heading__span {
    font-weight: normal;
}

@media (min-width: 700px) {
    .quiz-box__hero {
        padding: 0 95px;
    }
    .quiz-box__heading {
        text-align: center;
        font-size: 39px;
        padding: 40px 0 30px;
    }
    .quiz-box__heading:after {
        left: 0;
        right: 0;
        margin: auto;
    }
}