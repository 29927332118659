.iq-result-section-answer-distribution > .progress {
    height: 40px;
    border-radius: 1px;
}

.iq-result-section-answer-distribution > .progress > .progress-bar {
    line-height: 40px;
    font-size: 18px;
}

.iq-result-section-answer-distribution.lg > .progress {
    height: 60px;
}

.iq-result-section-answer-distribution.lg > .progress > .progress-bar {
    line-height: 60px;
    font-size: 21px;
}

.iq-result-section-answer-distribution.sm > .progress {
    height: 30px;
}

.iq-result-section-answer-distribution.sm > .progress > .progress-bar {
    line-height: 30px;
    font-size: 15px;
}