.progress-personality {
    text-align:    center;
    margin:        15px auto;
    background:    #1c1c1c;
    border-radius: 0;
    height:        70px;
    line-height:   70px;
    font-size:     34px;
}

.progress-personality > .progress-value {
    position: absolute;
    right:    0;
    left:     0;
    top:     0;
    color: #333333;
    /*color:    #ffffff;*/
}

.progress-personality > .progress-bar {
    background: #86744e;
}

.job .progress-personality > .progress-bar {
    background-color: #00729e;
}