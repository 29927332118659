.question-text-choice-text {
    font-family: "Open Sans";
    font-size: 16px;
    color: rgb(44,49,48);
    margin-bottom: 30px;
}
.question-text-choice-item {
    font-family: "Open Sans";
    font-size: 16px;
    color: rgb(44,49,48);

    cursor: pointer;

    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
         -o-user-select: none;
            user-select: none;
}

.question-text-choice-listgroupwrapper {
    padding: 0;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: rgb(52,112,133);
    border-color: rgb(52,112,133);
}