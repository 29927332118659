.visual-matrix > .visual-matrix-image {
    margin-bottom: 10px;
    text-align: center;
    min-height: 270px;
}

@media (max-width: 500px) {
    .visual-matrix > .visual-matrix-image img {
        max-width: 400px;
    }
}


.visual-matrix > .visual-matrix-choices {
    margin-bottom: 10px;
    text-align: center;
}

.visual-matrix > .visual-matrix-choices > a.visual-matrix-choice {
    display: inline-block;

    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;

    background-color: #fff;

    border: 3px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
         -o-transition: border .2s ease-in-out;
            transition: border .2s ease-in-out;
}

.visual-matrix > .visual-matrix-choices > a.visual-matrix-choice:hover,
.visual-matrix > .visual-matrix-choices > a.visual-matrix-choice:focus,
.visual-matrix > .visual-matrix-choices > a.visual-matrix-choice.active {
    border-color: #337ab7;
}

.visual-matrix > .visual-matrix-choices > .visual-matrix-choice > img {
    margin-right: auto;
    margin-left: auto;
    pointer-events: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
         -o-user-select: none;
            user-select: none;
}