.personality-carousel {
    background-color: #86744e;
    background-image: url('/image/personality/laptop.png');
    background-repeat: no-repeat;
    background-position: 200% 0%;
    background-size: 83%;
    color: #ffffff;
    position: relative;
    min-height: 450px;
}
.job .personality-carousel {
    background-color: #5B95C3;
    background-image: url('/image/personality/karriereguiden_930x620.png');
    background-position: 50% 50%;
    background-size: 100%;
}

.personality-carousel > .personality-carousel-top {
    padding: 50px 0 25px;
}

.personality-carousel > .personality-carousel-top h1 {
    font-family: 'Crimson Text', serif;
    font-size: 60px;
    margin: 0 0 0 100px;
    text-align: left;
    width: 500px;
}
.job .personality-carousel > .personality-carousel-top h1 {
    font-family: 'Vitesse', serif;
    margin: 0px 0 0 50px;
    text-shadow: -2px -2px 15px #444;
    width: auto;
}
.personality-carousel > .personality-carousel-center h2 {
    font-family: 'Crimson Text', serif;
    font-size: 26px;
    margin: 0 0 0 100px;
    text-align: left;
    width: 500px;
}
.job .personality-carousel > .personality-carousel-center h2 {
    font-family: 'Vitesse', serif;
    margin: 0 0 0 50px;
    text-shadow: -2px -2px 4px #444;
    width: 700px;
}

.personality-carousel > .personality-carousel-center {
    padding: 0 0 25px 0;
}
.job .personality-carousel > .personality-carousel-center {
    margin-bottom: 304px;
    padding: 0 0 25px 0;
}

.personality-carousel > .personality-carousel-bottom {
    height: 100px;
    text-align: center;
}
.personality-carousel > .personality-carousel-bottom > .personality-carousel-pre-c2a {
    font-family: 'Vitesse', serif;
    text-shadow: -2px -2px 15px #444;
    text-align: left;
    padding-left: 50px;
    font-weight: normal;
}
.job .personality-carousel > .personality-carousel-bottom {
    margin-top: 10px;
}
.personality-carousel > .personality-carousel-bottom button {
    font-weight: bold;
    margin-top: 25px;
    padding: 12px 20px;
}
.job .personality-carousel > .personality-carousel-bottom button {
    background-color: #ffff00;
    color: black;
    font-family: Tungsten, sans-serif;
    font-size: 28px;
    text-transform: uppercase;
    padding: 6px 24%;
}
.job .personality-carousel > .personality-carousel-sub {
    letter-spacing: 1.5px;
}

.personality-carousel > .personality-carousel-marker {
    background-color: #FDD416;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    position: absolute;
    right: 20px;
    margin-top: -150px;
}
.job .personality-carousel > .personality-carousel-marker {
    background-color: #ffff00;
    margin-top: -100px;
}

.personality-carousel-marker > .personality-carousel-marker-content {
    color: black;
    font-family: 'Tungsten', sanf-serif;
    position: relative;
    top: 50%;
    transform: translateY(-60%);
    text-align: center;
}
.job .personality-carousel-marker > .personality-carousel-marker-content {
    top: 60%;
}

.personality-carousel-marker-content > .personality-carousel-marker-number {
    font-weight: bold;
    font-size: 60px;
}
.job .personality-carousel-marker-content > .personality-carousel-marker-number {
    font-size: 36px;
 }
.personality-carousel-marker-content > .personality-carousel-marker-text {
    padding: 0 20px;
}
.job .personality-carousel-marker > .personality-carousel-marker-content {
    font-size: 36px;
}

@media (max-width: 1024px) {
    .personality-carousel {
        min-height: 0;
    }

    .personality-carousel > .personality-carousel-marker {
        display: none;
    }
    .job .personality-carousel > .personality-carousel-top h1,
    .job .personality-carousel > .personality-carousel-center h2,
    .personality-carousel > .personality-carousel-top h1,
    .personality-carousel > .personality-carousel-center h2 {
        margin-left: 20px;
    }
}

@media (max-width: 768px) {
    .personality-carousel > .personality-carousel-top h1 {
        font-size: 36px;
        width: 350px;
    }
    .job .personality-carousel > .personality-carousel-center h2,
    .personality-carousel > .personality-carousel-center h2 {
        font-size: 18px;
        width: 350px;
    }
    .job .personality-carousel > .personality-carousel-bottom {
        margin: 0;
    }
}
@media (max-width: 568px) {
    .personality-carousel {
        background-image: none;
    }
    .personality-carousel > .personality-carousel-bottom {
        height: 150px;
    }
    .job .personality-carousel > .personality-carousel-center {
        margin-bottom:150px;
    }
    .personality-carousel > .personality-carousel-top h1 {
        font-size: 24px;
        width: 95%;
    }
}
