.iq-result-distribution {
    padding: 10px 0;
}
.iq-result-curve-distribution-resultbox {
    margin-top: -10px;
}
@media (max-width: 768px) {
    .iq-result-curve-distribution-resultbox {
        margin-top: -20px;
        margin-left: -20px;
    }
}

.iq-result-curve-distribution-curve img {
    height: 160px;
    line-height: 160px;
    font-size: 50px;
    color: #ffffff;
}

.iq-result-curve-distribution-result {
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 14px;
    margin-top: -5px;
}
