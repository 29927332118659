.job-result-top {
    position: relative;
    margin-bottom: 50px;
}

.job-result-top button i {
    margin-right: 5px;
}

.job-image {
    background-color: #00729e;
    height: 225px;
    padding: 0;
}

.job-match {
    background-color: #00729e;
    color: white;
    display: flex;
    height: 250px;
    text-align: left;
    padding: 0px 20px 0 20px;
}
.job-traits {
    font-family: "Vitesse Medium";
    margin-bottom: 10px;
    width: 300px;
}
.job-traits-header {
    font-size: 1.2em;
}
.job-traits ul {
    padding-left: 20px;
}

.job-text {
    font-family: Vitesse;
    margin: 0 50px;
    padding: 50px 0 10px;
    text-align: left;
}
.job-text.personality-character-intro {
    font-family: "Vitesse";
    font-size: 1.2em;
}
.job .personality-text {
    font-family: "Vitesse Medium";
    font-size: 1.2em;
}

.job-result-match {
    align-self: flex-end;
    font-family: "Vitesse Book";
    font-size: 1.8em;
    margin-left: 20px;
}
.job-result-percent {
    font-family: "Vitesse Bold";
    font-weight: bold;
    font-size: 1.2em;
}

.job-result-share {
    width: 100%;
}
.job-result-share .btn-facebook {
    float: right;
    margin: 0 0 20px 0;
}
.job-result-title {
    clear: both;
}

h2.job-result-title {
    margin: 0 50px;
}
@media (max-width: 768px) {
    .job-traits {
        margin-bottom: 10px;
        width: 215px;
    }
    h2.job-result-title {
        clear: right;
        margin: 0 10px;
        padding: 10px 0 10px 20px;
    }
    .job-result-share {
        text-align: center;
    }
    .job-result-share .btn-facebook {
        float: none;
    }
    .job-text {
        padding: 0 0 0 10px;
    }
}
@media (max-width: 468px) {
    .job-match {
        display: block;
        height: auto;
    }
    .job-traits {
        width: auto;
    }
}