.diploma-teaser {
    min-height: 400px;
    background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ),url('/image/personality/diplom_teaser.jpg');
    background-size: 100%;
    padding: 15px;
}
.diploma-teaser-content {
    max-width: 500px;
}
.diploma-teaser h1 {
    color: white;
    font-family: "BenchNine";
    font-size: 36px;
    padding: 20px 50px;
    text-align: center;
    text-transform: uppercase;
}
.diploma-teaser p {
    color: white;
    font-size: 18px;
    padding: 0 50px;
    text-align: center;
}
.diploma-teaser .diploma-button {
    margin-top: 50px;
}

@media (max-width: 768px) {
    .diploma-teaser {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/image/personality/diplom_teaser_clean.jpg');
    }

    .diploma-teaser h1,
    .diploma-teaser p {
        max-width: none;
    }
}